import React from "react";
import { useEffect } from 'react';
import { useState } from 'react';
const colorMapping = {
    "running": "#62bd19",
    "idle": "gray",
    "stopped": "red"
}
export const PulsatingDot = ({ status }) => {
    const [color, setColor] = useState('gray');
    useEffect(() => {
        setColor(colorMapping[status]);
    }, [status])
    return <div className='p-dot'>
        <div className="ring-container">
           {status === 'running' && <div className="ringring" style={{ borderColor: color }}></div>}
            <div className="p-dot-circle" style={{ backgroundColor: color }}></div>
        </div>
    </div>
}

export default PulsatingDot;