import React, { useEffect, useState } from 'react';
import { Col, FormControl, InputGroup, Row } from "react-bootstrap";
import BasicIdoPreview from '../components/ido/basic/ido-preview';
import FairLaunchIdoPreview from '../components/ido/fairlaunch/ido-preview';
import ProxyFundedIdoPreview from '../components/ido/proxy-funded/ido-preview';
import { Paginated } from "../containers/paginated";
import { useIdos } from '../state/idos';

const IdoList = ({ idos }) => {
    return <Row className='w-100 '>
        {
            idos && idos.map((ido, index) => (
                <Col key={index} md={12} lg={4} className="mb-4  vault">
                    {
                        ido && ido.type === 'BASIC' && <BasicIdoPreview {...ido}></BasicIdoPreview>
                    }

                    {
                        ido && ido.type === 'PROXY_FUNDED' && <ProxyFundedIdoPreview {...ido}></ProxyFundedIdoPreview>
                    }

                    {
                        ido && ido.type === 'FAIRLAUNCH' && <FairLaunchIdoPreview {...ido}></FairLaunchIdoPreview>
                    }
                </Col>
            ))
        }
    </Row>;
};

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Idos() {
    const { isClient } = useIsClient();
    const { idos, paginate, onPageClick, onSearchByName } = useIdos();
    if (!isClient) { return null; }
    return (
        <Row className="mb-2 ">
            <Col md={12} className="mb-md-5 mb-4">
                <div className="reward-claim-component">
                    <Row className="mb-lg-3 mb-2 reward-header justify-content-center text-center">
                        <Col md={7} lg={7}>
                            <h2 className="title-seperator--centered">HedgePay Launchpad</h2>
                            <p>Welcome to HedgePay's LaunchPad! Let our community kickstart your project! Affordable, secure, and unique</p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col lg={8} md={6} sm={12} className="mb-md-5 mx-auto">
                <InputGroup className='form-hpay-input-group'>
                    <FormControl placeholder="Search here..." onChange={onSearchByName} className='form-hpay' />
                </InputGroup>
            </Col>
            <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
                <Row className='w-100'>
                    <IdoList idos={idos} />
                </Row>
            </Col>
            <Col md={12} className="mb-md-5 mb-4 d-flex justify-content-center flex-column align-items-center">
                <Paginated forcePage={paginate.page} nextLabel=">" onPageChange={({ selected }) => onPageClick({ page: selected + 1 })} pageCount={paginate.pageCount} previousLabel="<" renderOnZeroPageCount={null} />
            </Col>
        </Row>
    );
}


export default Idos;
